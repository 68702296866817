import ExpenseItem from "./ExpenseItem";

export default function ListingExpenses({
    expenses,
    expenseTotal,
    onDeleteExpense
}) {
    return (
        <div className="listing-expenses">
            <h3 className="listing-expenses__title">Listing expenses</h3>
            <ul className="listing-expenses-list">
                {expenses.map(expense => (
                    <ExpenseItem 
                        key={expense.id} 
                        expense={expense}
                        expenseTotal={expenseTotal}
                        onDeleteExpense={onDeleteExpense}
                        className="listing-expenses-list__item"
                    />
                ))}
            </ul>
        </div>
    );
}