import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEraser} from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../form/Button';

export default function IncomeItem({
    income,
    incomeTotal,
    onDeleteIncome,
    className
}) {    
    library.add(faEraser);

    function calculatePercentage(value, total) {
        return ((value / total) * 100).toFixed(2);
    }

    return (
        <li className={className}>
            <div className="listing-incomes-list__data-left">{income.description} - <strong>{income.value} RON</strong></div>
            <div className="listing-incomes-list__data-right">
                <span className="listing-incomes-list__percentage">{calculatePercentage(income.value, incomeTotal)}%</span>
                <Button onClick={() => onDeleteIncome(income)} className="listing-incomes-list__erase-button">
                    <FontAwesomeIcon icon="fa-solid fa-eraser" />
                </Button>
            </div>
        </li>
    );
}