export function Button ({
    type,
    onClick = null,
    className,
    children,
}) {
    return (
        <button 
            type={type} 
            onClick={onClick}
            className={className}>
            {children}
        </button>
    );
}