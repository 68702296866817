import AddExpenseBudget from './expense/AddExpenseBudget';
import ListingExpenses from './expense/ListingExpenses';

export default function ExpenseBudget({
    expenseTotal, 
    expenses, 
    onAddExpense,
    onDeleteExpense
}) {
    const nrExpenses = expenses.length;

    return (
        <div className="expense-budget manage-budget--expense">
            <h2 className="expense-budget__title">
                Expense budget: <span className="expense-budget__total">{expenseTotal} RON</span>
            </h2>

            <AddExpenseBudget onAddExpense={onAddExpense} />
                        
            {nrExpenses > 0 && (
                <ListingExpenses 
                    expenses={expenses}
                    expenseTotal={expenseTotal}
                    onDeleteExpense={onDeleteExpense}
                />
            )}
        </div>
    );
}