import { useState } from 'react';

import { Form } from '../../form/Form';
import { Input } from '../../form/Input';
import { Button } from '../../form/Button';

export default function AddExpenseBudget({ onAddExpense }) {
    const [description, setDescription] = useState('');
    const [value, setValue] = useState('');

    function handleSubmit(e) {
        e.preventDefault();

        if (description === '' && value === '') return;
        console.log(description);

        const id = crypto.randomUUID();
        const newExpense = {
            id,
            description,
            value
        };

        onAddExpense(newExpense);

        // Clear the inputs
        setDescription('');
        setValue('');
    }

    return (
        <div className="add-expense-budget">
            <h3 className="add-expense-budget__title">Add expense budget</h3>
            <Form onSubmit={handleSubmit} className="add-expense-budget-form">
                <div className="add-expense-budget-form__row">

                    {/* Description field */}
                    <div className="add-expense-budget-form__group">
                        <label htmlFor="add_Expense_description" className="add-expense-budget-form__label">Description</label>
                        <Input 
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="add-expense-budget-form__input"
                            id="add_Expense_description"
                            placeholder="Description"
                        />
                    </div>
                    
                    {/* Value field */}
                    <div className="add-expense-budget-form__group">
                        <label htmlFor="add_Expense_value" className="add-expense-budget-form__label">Value</label>
                        <Input 
                            type="number" 
                            value={value} 
                            onChange={(e) => setValue(Number(e.target.value))} 
                            className="add-expense-budget-form__input"
                            id="add_Expense_value"
                            placeholder="Value" 
                        />
                    </div>
                </div>
                
                <div className="add-expense-budget-form__group">
                    <Button type="submit" className="add-expense-budget-form__button">Add expense</Button>
                </div>
            </Form>
        </div>
    );
}