export function Input ({
    type,
    value,
    onChange,
    className,
    id,
    placeholder
}) {
    return (
        <input 
            type={type} 
            value={value} 
            onChange={onChange}
            className={className}
            id={id}
            placeholder={placeholder} 
        />
    );
}