import IncomeItem from "./IncomeItem";

export default function ListingIncomes({
    incomes, 
    incomeTotal,
    onDeleteIncome
}) {
    return (
        <div className="listing-incomes">
            <h3 className="listing-incomes__title">Listing incomes</h3>
            <ul className="listing-incomes-list">
                {incomes.map(income => (
                    <IncomeItem 
                        key={income.id} 
                        income={income}
                        incomeTotal={incomeTotal}
                        onDeleteIncome={onDeleteIncome}
                        className="listing-incomes-list__item"
                    />
                ))}
            </ul>
        </div>
    );
}