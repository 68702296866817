import './sass/App.scss';
import Budget from './components/budget/Budget';

function App() {
    return (
        <main className="app">
            <Budget />
        </main>
    );
}

export default App;
