import { useState } from 'react';

import TotalBudget from './TotalBudget';
import IncomeBudget from './IncomeBudget';
import ExpenseBudget from './ExpenseBudget';

const initialIncomeBudgets = [
    {
        id: 1,
        description: 'Income item 1',
        value: 1000,
    },

    {
        id: 2,
        description: 'Income item 2',
        value: 500,
    },

    {
        id: 3,
        description: 'Income item 3',
        value: 250,
    },
];

const initialExpenseeBudgets = [
    {
        id: 1,
        description: 'Expense item 1',
        value: 500,
    },

    {
        id: 2,
        description: 'Expense item 2',
        value: 250,
    },

    {
        id: 3,
        description: 'Expense item 3',
        value: 125,
    },
]

export default function Budget() {
    const [incomes, setIncomes] = useState(initialIncomeBudgets);
    const [incomeTotal, setIncomeTotal] = useState(calculateBudgetTotal(initialIncomeBudgets));
    const [expenses, setExpenses] = useState(initialExpenseeBudgets);
    const [expenseTotal, setExpenseTotal] = useState(calculateBudgetTotal(initialExpenseeBudgets));
    const total = incomeTotal - expenseTotal;

    // Calculate income total
    function calculateBudgetTotal(budgetItems) {
        let sum = 0;
        
        budgetItems.map(item => {
            sum += item.value;
        });

        return sum;
    }

    
    // Income
    function handleAddIncome(income) {
        setIncomeTotal(incomeTotal => incomeTotal + Number(income.value));
        setIncomes(incomes => [...incomes, income]);
    }

    function handleDeleteIncome(incomeItem) {
        setIncomeTotal(incomeTotal => incomeTotal - Number(incomeItem.value));
        setIncomes(incomes => incomes.filter(income => income.id !== incomeItem.id));
    }
    
    // Expense
    function handleAddExpense(expense) {
        setExpenseTotal(expenseTotal => expenseTotal + Number(expense.value));
        setExpenses(expenses => [...expenses, expense]);
    }
    
    function handleDeleteExpense(expenseItem) {
        setExpenseTotal(expenseTotal => expenseTotal - Number(expenseItem.value));
        setExpenses(expenses => expenses.filter(expense => expense.id !== expenseItem.id));
    }

    return (
        <div className="budget">
            <TotalBudget total={total} />
            
            <div className="manage-budget">
                <IncomeBudget 
                    incomeTotal={incomeTotal}
                    incomes={incomes} 
                    onAddIncome={handleAddIncome}
                    onDeleteIncome={handleDeleteIncome}
                />

                <ExpenseBudget
                    expenseTotal={expenseTotal}
                    expenses={expenses} 
                    onAddExpense={handleAddExpense}
                    onDeleteExpense={handleDeleteExpense}
                />
            </div>
        </div>
    );
}