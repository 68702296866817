export default function TotalBudget({
    total
}) {
    return (
        <div className="total-budget">
            <h2 className="total-budget__title">
                Total budget: <span className="total-budget__total">{total} RON</span>
            </h2>
        </div>
    );
}