import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEraser} from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../form/Button';

export default function ExpenseItem({
    expense,
    expenseTotal,
    onDeleteExpense,
    className
}) {    
    library.add(faEraser);

    function calculatePercentage(value, total) {
        return ((value / total) * 100).toFixed(2);
    }

    return (
        <li className={className}>
            <div className="listing-expenses-list__data-left">{expense.description} - <strong>{expense.value} RON</strong></div>
            <div className="listing-expenses-list__data-right">
                <span className="listing-expenses-list__percentage">{calculatePercentage(expense.value, expenseTotal)}%</span>
                <Button onClick={() => onDeleteExpense(expense)} className="listing-expenses-list__erase-button">
                    <FontAwesomeIcon icon="fa-solid fa-eraser" />
                </Button>
            </div>
        </li>
    );
}