import AddIncomeBudget from './income/AddIncomeBudget';
import ListingIncomes from './income/ListingIncomes';

export default function IncomeBudget({
    incomeTotal,
    incomes, 
    onAddIncome,
    onDeleteIncome
}) {
    const nrIncomes = incomes.length;

    return (
        <div className="income-budget manage-budget--income">
            <h2 className="income-budget__title">
                Income budget: <span className="income-budget__total">{incomeTotal} RON</span>
            </h2>

            <AddIncomeBudget onAddIncome={onAddIncome} />
                        
            {nrIncomes > 0 && (
                <ListingIncomes 
                    incomes={incomes}
                    incomeTotal={incomeTotal}
                    onDeleteIncome={onDeleteIncome}
                />
            )}
        </div>
    );
}